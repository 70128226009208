<i18n>
{
  "de": {
    "addBuildings": "Liegenschaften hinzufügen",
    "identifierTitle": "Liegenschaftsnr.",
    "descriptionTitle": "Bezeichnung",
    "zipCodeTitle": "Postleitzahl",
    "municipalityTitle": "Gemeinde",
    "uploadStateTitle": "Status",
    "uploadFile": "JSON-Datei auswählen:"
  }
}
</i18n>

<template>
  <div class="add-buildings-to-portfolio">
    <div v-if="addBuildings">
      <DetailList has-header class="import-table">
        <template #header>
          <span>{{ $t('identifierTitle') }}</span>
          <span>{{ $t('descriptionTitle') }}</span>
          <span>{{ $t('zipCodeTitle') }}</span>
          <span>{{ $t('municipalityTitle') }}</span>
          <span>{{ $t('uploadStateTitle') }}</span>
        </template>
        <li v-for="(building, index) in addBuildings" :key="index">
          <span>{{ building.identifier }}</span>
          <span>{{ building.description }}</span>
          <span>{{ building.zip_code }}</span>
          <span>{{ building.municipality }}</span>
          <span>{{ uploadState[index] }}</span>
        </li>
      </DetailList>
      <ButtonWrapper>
        <button class="button" @click="onAddBuilding">{{ $t('addBuildings') }}</button>
      </ButtonWrapper>
    </div>
    <div v-else>
      <h3>{{ $t('uploadFile') }}</h3>
      <input id="building-json-field" type="file" accept=".json" @change="onFileChanged" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'

import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'

export default {
  components: {
    ButtonWrapper,
    DetailList,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      id: null,
      fileReader: null,
      addBuildings: null,
      pending: false,
      uploadState: [],
    }
  },

  methods: {
    ...mapActions({
      addBuilding: 'building/addBuilding',
    }),

    onFileChanged(event) {
      const file = event.target.files[0]
      this.fileReader = new FileReader()
      this.fileReader.onloadend = this.onFileRead
      this.fileReader.readAsText(file)
    },

    onFileRead(event) {
      const content = this.fileReader.result
      const parsedObj = JSON.parse(content)
      if (Array.isArray(parsedObj)) {
        this.addBuildings = parsedObj
      } else {
        this.addBuildings = [parsedObj]
      }
    },

    async onAddBuilding() {
      this.pending = true
      this.uploadState = this.addBuildings.map((b) => 'PENDING')
      for (let idx = 0; idx < this.addBuildings.length; idx++) {
        const building = this.addBuildings[idx]
        try {
          this.uploadState[idx] = 'UPLOADING'
          await this.addBuilding({ building: building, portfolioId: this.portfolio.id })
          this.uploadState[idx] = 'SUCCESS'
        } catch (error) {
          this.uploadState[idx] = 'ERROR'
        }
        Vue.set(this.uploadState, [...this.uploadState]) // Ensure reactivity
      }
      this.pending = false
    },
  },
}
</script>

<style>
.add-buildings-to-portfolio .detail-list.import-table > ul > li {
  grid-template-columns: 1fr 4fr 1fr 2fr 1fr;
}
</style>
